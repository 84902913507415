import React from "react";
import { Container, Row, Col, Navbar, NavbarBrand, NavbarToggler, Collapse, NavbarText, Nav, NavItem, NavLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Alert, FormText } from 'reactstrap'
import { signIn, getTeams, updateTeam, getImageURL, uploadImage, sendResetEmail } from '../../services/team'
import { faSpinner, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from "@reach/router"

class Header extends React.Component {


  constructor(props) {

    super(props);

    this.state = {
      tab: '',
      token: null,
      isOpen: false,
      email: '',
      password: '',
      signInError: '',
      open: false,
      loadingTeams: false,
      savingTeam: false,
      teams: [],
      selectedTeamId: -1,
      unsavedChanges: false,
      showSignOutButton: false,
      logoEditInput: '',
      signingIn: false,
      forgotPassword: false,
      resetEmail: '',
      forgotEmailSent: false,
      forgotEmailSending: false
    };

    this.toggle = this.toggle.bind(this);

  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }


  async toggleModal() {

    if (localStorage.getItem("token")) {

      let teams = await getTeams(localStorage.getItem("token"));
      if (teams.error == "Unauthorized") {
        this.logOut();

      }
      else {        
        navigate("/manage");
      }

    }

    this.setState({ open: !this.state.open, showSignOutButton: false, forgotPassword: false, token: localStorage.getItem('token') });

  }

  async logOut() {
    localStorage.removeItem('token');
    this.setState({ open: false, teams: [], token: null });
  }

  async componentDidMount() {

    if (localStorage.getItem("token") !== "") {
      this.setState({ token: localStorage.getItem("token") });
    }
  }

  async sendResetEmail() {
    this.setState({ forgotEmailSent: false, forgotEmailSending: true });
    await sendResetEmail(this.state.resetEmail);
    this.setState({ forgotEmailSent: true, forgotEmailSending: false });
  }

  async signIn() {
    this.setState({ signInError: "", signingIn: true })
    let response = await signIn(this.state.email, this.state.password);

    if (response.token !== "") {
      this.setState({ signingIn: false, open: false, token: response.token });
      await localStorage.setItem('token', response.token);
      console.log('here');
      
      navigate("/manage")
      
    }
    else {
      this.setState({ signInError: response.error, signingIn: false, token: null })
    }

  }

  /*
    toggleSignOutButton() {
      this.setState({ showSignOutButton: true });
    }
  */

  render() {

    var { token } = this.state;

    return (

      <div>
        <Navbar color="dark" dark expand="md" fixed="fixed" sticky={'top'}>
          <Container>
            <NavbarBrand href="/"><img alt="Fast Pitch Hub" src="/images/logo-1.png" style={{ height: "40px" }} /></NavbarBrand>

              <Nav className="mr-auto d-none d-md-flex" navbar>
                <NavItem>
                  <NavLink href="/softball-map">Softball Map</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/bats">Compare Bats</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/articles">Articles</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/about-us">About Us</NavLink>
                </NavItem>
              </Nav>

            <Nav className="ml-auto" navbar>              
              <Button color="success" type="button" onClick={() => this.toggleModal()}>{(token) ? 'Manage' : 'Manage'}</Button>
            </Nav>
          </Container>
        </Navbar>

        <div className="d-block d-md-none" style={{backgroundColor: "#eeeeee", padding: "10px 5px"}}>
        <Container>
          <Row>            
            <Col style={{textAlign: "left"}}>
              <a style={{color: "black", whiteSpace: "nowrap", marginRight: "5%"}} href="/"><FontAwesomeIcon width="0" icon={faHome} /></a>
              <a style={{color: "black", whiteSpace: "nowrap", marginRight: "5%"}} href="/softball-map">Softball Map</a>
              <a style={{color: "black", whiteSpace: "nowrap", marginRight: "5%"}} href="/bats">Bats</a>
              <a style={{color: "black", whiteSpace: "nowrap", marginRight: "5%"}} href="/articles">Articles</a>              
            </Col>
          </Row>
        </Container>
        </div>




        <Modal isOpen={token == null && !this.state.forgotPassword && this.state.open} toggle={() => this.toggleModal()}>
          <ModalHeader toggle={() => this.toggleModal()}>Sign In</ModalHeader>
          <ModalBody>
            <div style={{ marginBottom: "25px" }}>Enter your email address and password.  If this is your first time logging in, a new account will be created for you.</div>
            <Form>
              <FormGroup row>
                <Label for="exampleEmail" sm={2}>Email</Label>
                <Col sm={10}>
                  <Input type="email" value={this.state.email} name="email" onChange={(e) => this.setState({ email: e.target.value })} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="examplePassword" sm={2}>Password</Label>
                <Col sm={10}>
                  <Input type="password" value={this.state.password} name="password" onChange={(e) => this.setState({ password: e.target.value })} />
                </Col>
              </FormGroup>



            </Form>
            <span style={{ color: "cornflowerblue", textDecoration: "underline", cursor: "pointer" }} onClick={() => this.setState({ forgotPassword: true })} onKeyDown={() => this.setState({ forgotPassord: true })} role="button" tabIndex={0}>Forgot Password</span>
            {this.state.signingIn && <Alert color="primary"><FontAwesomeIcon icon={faSpinner} spin /> Signing In...</Alert>}
            {this.state.signInError !== "" && <Alert color="danger">{this.state.signInError}</Alert>}

            <div style={{ color: "#999", fontSize: ".8em", marginTop: "10px" }}>You agree that by signing up for this service you consent to receive emails from FastpitchHub.com.  Don't worry, we won't spam, we just want to keep you updated on anything new.</div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.signIn()}>Sign In</Button>{' '}
          </ModalFooter>
        </Modal>

        <Modal isOpen={token == null && this.state.forgotPassword && this.state.open} toggle={() => this.toggleModal()}>
          <ModalHeader toggle={() => this.toggleModal()}>Forgot Password</ModalHeader>
          <ModalBody>
            <div style={{ marginBottom: "25px" }}>Enter your email address.  If we find a matching account, we'll send you an email with instructions on how to reset your password.</div>
            <Form>
              <FormGroup row>
                <Label for="exampleEmail" sm={2}>Email</Label>
                <Col sm={10}>
                  <Input type="email" value={this.state.resetEmail} name="email" onChange={(e) => this.setState({ resetEmail: e.target.value })} />
                </Col>
              </FormGroup>
            </Form>
            {this.state.forgotEmailSent && <Alert color="success">Forgot Password Email Sent.</Alert>}
            {this.state.forgotEmailSending && <Alert color="primary"><FontAwesomeIcon icon={faSpinner} spin /> Sending Password Reset Email...</Alert>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.sendResetEmail()}>Send Forgot Password Email</Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default Header;