import React from "react"
import PropTypes from "prop-types"
import Header from "./header/header"
import { Container, Row, Col } from 'reactstrap';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './bootstrap.min.css';

const Layout = ({ children }) => {
  
  return (
    <>
      <Header/>
      <div
        style={{
          margin: `0 auto`,
          paddingTop: 0,          
        }}
      >
        <main>{children}</main>

        
        <footer>
          <Container>
            <Row>
              <Col >@2020 FastPitchHub</Col>
              <Col style={{textAlign: "right"}}>
                <a href="http://fb.me/fastpitchhub" target="_blank" rel="nofollow noreferrer noopener" style={{color: "#aaa"}}><FontAwesomeIcon icon={faFacebookSquare} width="0" style={{fontSize: "26px"}}  /></a>
              </Col>
            </Row>
          
          </Container>
         
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
