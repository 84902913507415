export async function newsletterSignup(email, name, city, state) {
  let dataURL = `https://2dd0hiqvz0.execute-api.us-east-1.amazonaws.com/default/fph-newsletter-signup-lambda`;
  let body = {"email": email, "name": name, "city": city, "state": state };

  let options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',    
    headers: {
      'Content-Type': 'application/json'      
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(body)
  }

  let response = await fetch(dataURL, options);
  
  return await response.status
}

export async function signIn(email, password) {
  let url = `https://pz3eiq1r27.execute-api.us-east-1.amazonaws.com/production/fph-authenticate-user-lambda`;

  let body = {"email": email, "password": password};

  let options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',    
    headers: {
      'Content-Type': 'application/json'      
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(body)
  }

  return await fetch(url, options).then(async (r)=> {
    
    var response = {
      token: "",
      error: ""
    }

    switch (r.status) {
      case 200:
        response["token"] = await r.json()
        break;                
      case 400:
        response["error"] = await r.text()
        break;                
      case 401:
      default:              
        response["error"] = "Invalid Password"
        break;                
    }
    
    return response;
  })
  
  
  
}


export async function updateTeam(token, team) {
  let url = `https://pz3eiq1r27.execute-api.us-east-1.amazonaws.com/production/fph-save-user-team-lambda`;  

  let options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',    
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token      
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(team)
  }

  return await fetch(url, options).then(async (r)=> {
    
    var response = {
      token: "",
      error: ""
    }

    switch (r.status) {
      case 200:
        response["error"] = ""
        break;                
      case 400:
        response["error"] = await r.text()
        break;                
      case 401:
      default:                
        response["error"] = "Unauthorized"
        break;                
    }
    
    return response;
  })
  
  
  
}



export async function getTeams(token) {
  let url = `https://pz3eiq1r27.execute-api.us-east-1.amazonaws.com/production/fph-get-user-teams-lambda`;

  console.log(token);

  let options = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',    
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token      

    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',    
  }

  return await fetch(url, options).then(async (r)=> {
    
    var response = {
      teams: [],
      error: ""
    }

    switch (r.status) {
      case 200:
        response["teams"] = await r.json()
        break;                
      case 400:
        response["error"] = await r.text()
        break;                
      case 401:
      default:              
        response["error"] = "Unauthorized"
        break;  
    }
    
    return response;
  })
  
  
  
}


export async function getImageURL(token, type, team) {
  let url = `https://1p4zhwbed4.execute-api.us-east-1.amazonaws.com/default/fph-logo-url-lambda`;  

  let options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',    
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token      
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({type: type, team: team })
  }

  return await fetch(url, options).then(async (r)=> {
    
    var response = {
      url: "",
      error: ""
    }

    switch (r.status) {
      case 200:
        response["url"] = await r.json()
        response["error"] = ""
        break;                
      case 401:
      default:              

        response["error"] = "Unauthorized"
        break;                
    }
    
    return response;
  })
  
  
  
}

export async function uploadImage(url, file) {
  

  let options = {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',    
    headers: {
      'Content-Type': file.type,
      'x-amz-acl': 'public-read'      
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: file
  }

  return await fetch(url, options).then(async (r)=> {
    
    var response = {      
      error: ""
    }

    switch (r.status) {
      case 200:        
        response["error"] = ""
        break;                
      case 401:
      default:                        
        response["error"] = "Unauthorized"
        break;                
    }
    
    return response;
  })
  
  
  
}

export async function sendResetEmail(email) {
  
  let url = `https://9mk1f2t4ud.execute-api.us-east-1.amazonaws.com/default/fph-forgot-password-email-lambda`;  

  let options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',    
    headers: {
      'Content-Type': 'application/json',      
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({email: email })
  }

  return await fetch(url, options).then(async (r)=> {    
    return true;
  })
  
  


}

export async function resetPassword(email, password, hash) {
  
  let url = `https://rzf2i8dfo7.execute-api.us-east-1.amazonaws.com/default/fph-reset-password-lambda`;  

  let options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',    
    headers: {
      'Content-Type': 'application/json',      
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({email, password, hash })
  }

  var response = {      
    error: ""
  }

  return await fetch(url, options).then(async (r)=> {    

    switch (r.status) {
      case 200:                                
        response["error"] = ""
        break;                
      case 401:      
      default:                        
        response["error"] = "Unauthorized"
        break;                
      
    }

    return response;

  }).catch(() => {
    response["error"] = "Unauthorized"
    return response;
  })  


}


/*

export function getPlayerData()
{

  return [
    {
      id: 1,
      name: "Chaselyn",
      age: "14U",
      positions: ["Pitcher", "Catcher", "Outfield"],
      lat: 34.981510,
      lng: -80.771361,
      icon: "/images/chasey1.jpg"
    },
  ]


}


export function getTeamData()
{
  return [
    {
      id: 1,
      team: "Ultimate Performance 12U",
      description: "Ultimate Performance 12U is focused on player growth in a relaxed environment",
      age: "12U",
      lat: 34.981510,
      lng: -80.771361,
      icon: "https://cdn.evbuc.com/images/63888368/312384293628/2/logo.jpg"
    },
    {
      id: 2,
      team: "Ultimate Performance 14U",
      description: "Ultimate Performance 14U is a nonexistent team",
      age: "14U",
      lat: 34.951510,
      lng: -80.671361,
      icon: "https://cdn.evbuc.com/images/63888368/312384293628/2/logo.jpg"
    },
    {
      id: 3,
      team: "Carolina Elite 12U",
      description: "Carolina Elite 12U description",
      age: "12U",
      lat: 34.951510,
      lng: -80.681361,
      icon: "https://pbs.twimg.com/profile_images/914270454814212096/n423w4OM.jpg"
    },
    {
      id: 4,
      team: "Knockouts Elite Kelly 14U",
      description: "Knockouts Description",
      age: "14U",
      lat: 35.151510,
      lng: -80.671361,
      icon: "https://www.hometeamsonline.com/photos/softball/KNOCKOUTSELITEFASTPI/homelogo.jpg"
    }
  ]
}


export function getArticleData() {
  return [
    {
      date: "January 3rd, 2020",
      author: "Billy Bob Thornton",
      headline: "This is an article title",
      category: "USA Softball",
      articleHtml: `
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mauris sit amet massa vitae tortor condimentum. Sit amet consectetur adipiscing elit ut aliquam. Nec tincidunt praesent semper feugiat nibh. Sed elementum tempus egestas sed sed risus. Feugiat pretium nibh ipsum consequat. Semper risus in hendrerit gravida rutrum quisque non tellus orci. Diam maecenas sed enim ut sem viverra aliquet eget sit. Et netus et malesuada fames ac. Lectus magna fringilla urna porttitor rhoncus dolor. Aliquet nibh praesent tristique magna sit.
      <br /><br />
      Arcu cursus euismod quis viverra nibh. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Dignissim sodales ut eu sem integer vitae justo eget magna. Nunc mi ipsum faucibus vitae aliquet. Donec adipiscing tristique risus nec feugiat in fermentum posuere urna. Tincidunt eget nullam non nisi. Quis auctor elit sed vulputate mi sit amet mauris. Nunc scelerisque viverra mauris in aliquam sem fringilla ut morbi. Condimentum mattis pellentesque id nibh tortor id aliquet lectus proin. Vulputate dignissim suspendisse in est ante in nibh mauris cursus. Cras pulvinar mattis nunc sed blandit libero volutpat sed cras. Consequat semper viverra nam libero justo laoreet sit.
      <br /><br />
      Feugiat pretium nibh ipsum consequat nisl vel pretium lectus quam. Risus nullam eget felis eget nunc lobortis mattis aliquam. Tellus in hac habitasse platea. Sagittis orci a scelerisque purus. Placerat vestibulum lectus mauris ultrices eros in. Sed odio morbi quis commodo odio aenean sed adipiscing diam. Morbi enim nunc faucibus a pellentesque sit. Integer malesuada nunc vel risus commodo viverra maecenas. Arcu non odio euismod lacinia at quis risus. A diam sollicitudin tempor id eu nisl. Sapien pellentesque habitant morbi tristique senectus et.
      <br /><br />
      At lectus urna duis convallis convallis tellus id interdum. Velit euismod in pellentesque massa placerat duis ultricies lacus. Etiam dignissim diam quis enim. Sit amet nisl purus in mollis. Tempor nec feugiat nisl pretium fusce. Lectus mauris ultrices eros in. Mi in nulla posuere sollicitudin aliquam ultrices sagittis. Suspendisse in est ante in nibh mauris cursus. Quam elementum pulvinar etiam non quam lacus suspendisse. Habitant morbi tristique senectus et netus et malesuada fames. Diam sollicitudin tempor id eu nisl. Praesent semper feugiat nibh sed pulvinar proin. Blandit volutpat maecenas volutpat blandit aliquam etiam erat. Et molestie ac feugiat sed lectus vestibulum mattis. Nibh sit amet commodo nulla facilisi. Eget egestas purus viverra accumsan in nisl nisi. Justo donec enim diam vulputate ut pharetra. A arcu cursus vitae congue mauris rhoncus aenean.
      <br /><br />
      Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Imperdiet dui accumsan sit amet nulla facilisi. Faucibus et molestie ac feugiat sed. Felis donec et odio pellentesque diam volutpat. Morbi non arcu risus quis. Porttitor leo a diam sollicitudin. Tristique magna sit amet purus. Enim sit amet venenatis urna cursus eget nunc scelerisque viverra. Nam libero justo laoreet sit amet cursus sit amet dictum. Egestas fringilla phasellus faucibus scelerisque eleifend. Lorem sed risus ultricies tristique. Tristique senectus et netus et malesuada fames ac turpis egestas. Enim sit amet venenatis urna.
      `,      
      image: "https://www.ledger-enquirer.com/latest-news/6mhgpb/picture232175382/alternates/FREE_1140/0702_COSP_Team%20USA%20softball%207-1-19_0087.jpg",
      slug: "ask-coach-bo/should-i-stay-in-my-legs"
    },
    {
      date: "December 19th, 2019",
      author: "Jessica Bowen",
      headline: "Should I Stay In My Legs?",
      category: "Ask Coach Bo",
      articleHtml: `
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mauris sit amet massa vitae tortor condimentum. Sit amet consectetur adipiscing elit ut aliquam. Nec tincidunt praesent semper feugiat nibh. Sed elementum tempus egestas sed sed risus. Feugiat pretium nibh ipsum consequat. Semper risus in hendrerit gravida rutrum quisque non tellus orci. Diam maecenas sed enim ut sem viverra aliquet eget sit. Et netus et malesuada fames ac. Lectus magna fringilla urna porttitor rhoncus dolor. Aliquet nibh praesent tristique magna sit.
      <br /><br />
      Arcu cursus euismod quis viverra nibh. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Dignissim sodales ut eu sem integer vitae justo eget magna. Nunc mi ipsum faucibus vitae aliquet. Donec adipiscing tristique risus nec feugiat in fermentum posuere urna. Tincidunt eget nullam non nisi. Quis auctor elit sed vulputate mi sit amet mauris. Nunc scelerisque viverra mauris in aliquam sem fringilla ut morbi. Condimentum mattis pellentesque id nibh tortor id aliquet lectus proin. Vulputate dignissim suspendisse in est ante in nibh mauris cursus. Cras pulvinar mattis nunc sed blandit libero volutpat sed cras. Consequat semper viverra nam libero justo laoreet sit.
      <br /><br />
      Feugiat pretium nibh ipsum consequat nisl vel pretium lectus quam. Risus nullam eget felis eget nunc lobortis mattis aliquam. Tellus in hac habitasse platea. Sagittis orci a scelerisque purus. Placerat vestibulum lectus mauris ultrices eros in. Sed odio morbi quis commodo odio aenean sed adipiscing diam. Morbi enim nunc faucibus a pellentesque sit. Integer malesuada nunc vel risus commodo viverra maecenas. Arcu non odio euismod lacinia at quis risus. A diam sollicitudin tempor id eu nisl. Sapien pellentesque habitant morbi tristique senectus et.
      <br /><br />
      At lectus urna duis convallis convallis tellus id interdum. Velit euismod in pellentesque massa placerat duis ultricies lacus. Etiam dignissim diam quis enim. Sit amet nisl purus in mollis. Tempor nec feugiat nisl pretium fusce. Lectus mauris ultrices eros in. Mi in nulla posuere sollicitudin aliquam ultrices sagittis. Suspendisse in est ante in nibh mauris cursus. Quam elementum pulvinar etiam non quam lacus suspendisse. Habitant morbi tristique senectus et netus et malesuada fames. Diam sollicitudin tempor id eu nisl. Praesent semper feugiat nibh sed pulvinar proin. Blandit volutpat maecenas volutpat blandit aliquam etiam erat. Et molestie ac feugiat sed lectus vestibulum mattis. Nibh sit amet commodo nulla facilisi. Eget egestas purus viverra accumsan in nisl nisi. Justo donec enim diam vulputate ut pharetra. A arcu cursus vitae congue mauris rhoncus aenean.
      <br /><br />
      Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Imperdiet dui accumsan sit amet nulla facilisi. Faucibus et molestie ac feugiat sed. Felis donec et odio pellentesque diam volutpat. Morbi non arcu risus quis. Porttitor leo a diam sollicitudin. Tristique magna sit amet purus. Enim sit amet venenatis urna cursus eget nunc scelerisque viverra. Nam libero justo laoreet sit amet cursus sit amet dictum. Egestas fringilla phasellus faucibus scelerisque eleifend. Lorem sed risus ultricies tristique. Tristique senectus et netus et malesuada fames ac turpis egestas. Enim sit amet venenatis urna.
      `,      
      image: "https://www.baltimoresun.com/resizer/D2L3VV0PWnuxDPSKIPOelbKEXRA=/415x233/top/www.trbimg.com/img-5aaf51d2/turbine/bs-1521439182-t4qwwrmva0-snap-image",
      slug: "ask-coach-bo/should-i-stay-in-my-legs"
    },
    {
      date: "January 3rd, 2020",
      author: "Billy Bob Thornton",
      headline: "This is an article title",
      category: "Olympic Sports",
      articleHtml: `
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mauris sit amet massa vitae tortor condimentum. Sit amet consectetur adipiscing elit ut aliquam. Nec tincidunt praesent semper feugiat nibh. Sed elementum tempus egestas sed sed risus. Feugiat pretium nibh ipsum consequat. Semper risus in hendrerit gravida rutrum quisque non tellus orci. Diam maecenas sed enim ut sem viverra aliquet eget sit. Et netus et malesuada fames ac. Lectus magna fringilla urna porttitor rhoncus dolor. Aliquet nibh praesent tristique magna sit.
      <br /><br />
      Arcu cursus euismod quis viverra nibh. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Dignissim sodales ut eu sem integer vitae justo eget magna. Nunc mi ipsum faucibus vitae aliquet. Donec adipiscing tristique risus nec feugiat in fermentum posuere urna. Tincidunt eget nullam non nisi. Quis auctor elit sed vulputate mi sit amet mauris. Nunc scelerisque viverra mauris in aliquam sem fringilla ut morbi. Condimentum mattis pellentesque id nibh tortor id aliquet lectus proin. Vulputate dignissim suspendisse in est ante in nibh mauris cursus. Cras pulvinar mattis nunc sed blandit libero volutpat sed cras. Consequat semper viverra nam libero justo laoreet sit.
      <br /><br />
      Feugiat pretium nibh ipsum consequat nisl vel pretium lectus quam. Risus nullam eget felis eget nunc lobortis mattis aliquam. Tellus in hac habitasse platea. Sagittis orci a scelerisque purus. Placerat vestibulum lectus mauris ultrices eros in. Sed odio morbi quis commodo odio aenean sed adipiscing diam. Morbi enim nunc faucibus a pellentesque sit. Integer malesuada nunc vel risus commodo viverra maecenas. Arcu non odio euismod lacinia at quis risus. A diam sollicitudin tempor id eu nisl. Sapien pellentesque habitant morbi tristique senectus et.
      <br /><br />
      At lectus urna duis convallis convallis tellus id interdum. Velit euismod in pellentesque massa placerat duis ultricies lacus. Etiam dignissim diam quis enim. Sit amet nisl purus in mollis. Tempor nec feugiat nisl pretium fusce. Lectus mauris ultrices eros in. Mi in nulla posuere sollicitudin aliquam ultrices sagittis. Suspendisse in est ante in nibh mauris cursus. Quam elementum pulvinar etiam non quam lacus suspendisse. Habitant morbi tristique senectus et netus et malesuada fames. Diam sollicitudin tempor id eu nisl. Praesent semper feugiat nibh sed pulvinar proin. Blandit volutpat maecenas volutpat blandit aliquam etiam erat. Et molestie ac feugiat sed lectus vestibulum mattis. Nibh sit amet commodo nulla facilisi. Eget egestas purus viverra accumsan in nisl nisi. Justo donec enim diam vulputate ut pharetra. A arcu cursus vitae congue mauris rhoncus aenean.
      <br /><br />
      Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Imperdiet dui accumsan sit amet nulla facilisi. Faucibus et molestie ac feugiat sed. Felis donec et odio pellentesque diam volutpat. Morbi non arcu risus quis. Porttitor leo a diam sollicitudin. Tristique magna sit amet purus. Enim sit amet venenatis urna cursus eget nunc scelerisque viverra. Nam libero justo laoreet sit amet cursus sit amet dictum. Egestas fringilla phasellus faucibus scelerisque eleifend. Lorem sed risus ultricies tristique. Tristique senectus et netus et malesuada fames ac turpis egestas. Enim sit amet venenatis urna.
      `,      
      image: "https://static01.nyt.com/images/2011/01/20/sports/SOFTBALL1/SOFTBALL1-jumbo.jpg",
      slug: "ask-coach-bo/should-i-stay-in-my-legs"
    }

  ]
}
*/